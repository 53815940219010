"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.Hydrate = void 0;
exports.useHydrate = useHydrate;

var _react = _interopRequireDefault(require("react"));

var _core = require("../core");

var _QueryClientProvider = require("./QueryClientProvider");

function useHydrate(state, options = {}) {
  const queryClient = (0, _QueryClientProvider.useQueryClient)({
    context: options.context
  });

  const optionsRef = _react.default.useRef(options);

  optionsRef.current = options; // Running hydrate again with the same queries is safe,
  // it wont overwrite or initialize existing queries,
  // relying on useMemo here is only a performance optimization.
  // hydrate can and should be run *during* render here for SSR to work properly

  _react.default.useMemo(() => {
    if (state) {
      (0, _core.hydrate)(queryClient, state, optionsRef.current);
    }
  }, [queryClient, state]);
}

const Hydrate = ({
  children,
  options,
  state
}) => {
  useHydrate(state, options);
  return children;
};

exports.Hydrate = Hydrate;