"use strict";

exports.__esModule = true;
exports.QueryCache = void 0;

var _utils = require("./utils");

var _query = require("./query");

var _notifyManager = require("./notifyManager");

var _subscribable = require("./subscribable");

// CLASS
class QueryCache extends _subscribable.Subscribable {
  constructor(config) {
    super();
    this.config = config || {};
    this.queries = [];
    this.queriesMap = {};
  }

  build(client, options, state) {
    var _options$queryHash;

    const queryKey = options.queryKey;
    const queryHash = (_options$queryHash = options.queryHash) != null ? _options$queryHash : (0, _utils.hashQueryKeyByOptions)(queryKey, options);
    let query = this.get(queryHash);

    if (!query) {
      query = new _query.Query({
        cache: this,
        logger: client.getLogger(),
        queryKey,
        queryHash,
        options: client.defaultQueryOptions(options),
        state,
        defaultOptions: client.getQueryDefaults(queryKey),
        meta: options.meta
      });
      this.add(query);
    }

    return query;
  }

  add(query) {
    if (!this.queriesMap[query.queryHash]) {
      this.queriesMap[query.queryHash] = query;
      this.queries.push(query);
      this.notify({
        type: 'added',
        query
      });
    }
  }

  remove(query) {
    const queryInMap = this.queriesMap[query.queryHash];

    if (queryInMap) {
      query.destroy();
      this.queries = this.queries.filter(x => x !== query);

      if (queryInMap === query) {
        delete this.queriesMap[query.queryHash];
      }

      this.notify({
        type: 'removed',
        query
      });
    }
  }

  clear() {
    _notifyManager.notifyManager.batch(() => {
      this.queries.forEach(query => {
        this.remove(query);
      });
    });
  }

  get(queryHash) {
    return this.queriesMap[queryHash];
  }

  getAll() {
    return this.queries;
  }

  find(arg1, arg2) {
    const [filters] = (0, _utils.parseFilterArgs)(arg1, arg2);

    if (typeof filters.exact === 'undefined') {
      filters.exact = true;
    }

    return this.queries.find(query => (0, _utils.matchQuery)(filters, query));
  }

  findAll(arg1, arg2) {
    const [filters] = (0, _utils.parseFilterArgs)(arg1, arg2);
    return Object.keys(filters).length > 0 ? this.queries.filter(query => (0, _utils.matchQuery)(filters, query)) : this.queries;
  }

  notify(event) {
    _notifyManager.notifyManager.batch(() => {
      this.listeners.forEach(listener => {
        listener(event);
      });
    });
  }

  onFocus() {
    _notifyManager.notifyManager.batch(() => {
      this.queries.forEach(query => {
        query.onFocus();
      });
    });
  }

  onOnline() {
    _notifyManager.notifyManager.batch(() => {
      this.queries.forEach(query => {
        query.onOnline();
      });
    });
  }

}

exports.QueryCache = QueryCache;