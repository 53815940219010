"use strict";

exports.__esModule = true;
exports.Subscribable = void 0;

class Subscribable {
  constructor() {
    this.listeners = [];
    this.subscribe = this.subscribe.bind(this);
  }

  subscribe(listener) {
    this.listeners.push(listener);
    this.onSubscribe();
    return () => {
      this.listeners = this.listeners.filter(x => x !== listener);
      this.onUnsubscribe();
    };
  }

  hasListeners() {
    return this.listeners.length > 0;
  }

  onSubscribe() {// Do nothing
  }

  onUnsubscribe() {// Do nothing
  }

}

exports.Subscribable = Subscribable;