"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault").default;

exports.__esModule = true;
exports.unstable_batchedUpdates = void 0;

var _reactDom = _interopRequireDefault(require("react-dom"));

const unstable_batchedUpdates = _reactDom.default.unstable_batchedUpdates;
exports.unstable_batchedUpdates = unstable_batchedUpdates;