"use strict";

exports.__esModule = true;
exports.MutationCache = void 0;

var _notifyManager = require("./notifyManager");

var _mutation = require("./mutation");

var _utils = require("./utils");

var _subscribable = require("./subscribable");

// CLASS
class MutationCache extends _subscribable.Subscribable {
  constructor(config) {
    super();
    this.config = config || {};
    this.mutations = [];
    this.mutationId = 0;
  }

  build(client, options, state) {
    const mutation = new _mutation.Mutation({
      mutationCache: this,
      logger: client.getLogger(),
      mutationId: ++this.mutationId,
      options: client.defaultMutationOptions(options),
      state,
      defaultOptions: options.mutationKey ? client.getMutationDefaults(options.mutationKey) : undefined,
      meta: options.meta
    });
    this.add(mutation);
    return mutation;
  }

  add(mutation) {
    this.mutations.push(mutation);
    this.notify({
      type: 'added',
      mutation
    });
  }

  remove(mutation) {
    this.mutations = this.mutations.filter(x => x !== mutation);
    this.notify({
      type: 'removed',
      mutation
    });
  }

  clear() {
    _notifyManager.notifyManager.batch(() => {
      this.mutations.forEach(mutation => {
        this.remove(mutation);
      });
    });
  }

  getAll() {
    return this.mutations;
  }

  find(filters) {
    if (typeof filters.exact === 'undefined') {
      filters.exact = true;
    }

    return this.mutations.find(mutation => (0, _utils.matchMutation)(filters, mutation));
  }

  findAll(filters) {
    return this.mutations.filter(mutation => (0, _utils.matchMutation)(filters, mutation));
  }

  notify(event) {
    _notifyManager.notifyManager.batch(() => {
      this.listeners.forEach(listener => {
        listener(event);
      });
    });
  }

  resumePausedMutations() {
    const pausedMutations = this.mutations.filter(x => x.state.isPaused);
    return _notifyManager.notifyManager.batch(() => pausedMutations.reduce((promise, mutation) => promise.then(() => mutation.continue().catch(_utils.noop)), Promise.resolve()));
  }

}

exports.MutationCache = MutationCache;