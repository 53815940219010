import * as React from 'react';
import { stylesheetSingleton } from './singleton';
export var styleHookSingleton = function () {
    var sheet = stylesheetSingleton();
    return function (styles) {
        React.useEffect(function () {
            sheet.add(styles);
            return function () {
                sheet.remove();
            };
        }, [styles]);
    };
};
