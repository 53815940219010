import { c as client, g as getClient } from './client-3a56f061.esm.js';
export { C as Client, I as InjectedConnector, C as WagmiClient, a as createClient, b as createStorage, a as createWagmiClient, b as createWagmiStorage, n as noopStorage } from './client-3a56f061.esm.js';
import { C as ConnectorAlreadyConnectedError, a as allChains, b as ConnectorNotFoundError, U as UserRejectedRequestError, S as SwitchChainNotSupportedError } from './base-159de546.esm.js';
export { A as AddChainError, k as ChainNotConfiguredError, c as Connector, C as ConnectorAlreadyConnectedError, b as ConnectorNotFoundError, l as SwitchChainError, S as SwitchChainNotSupportedError, U as UserRejectedRequestError, d as alchemyRpcUrls, a as allChains, e as chain, f as chainId, g as defaultChains, h as defaultL2Chains, i as etherscanBlockExplorers, j as infuraRpcUrls, n as normalizeChainId } from './base-159de546.esm.js';
import { Contract } from 'ethers/lib/ethers';
import { formatUnits, getAddress } from 'ethers/lib/utils';
import { Contract as Contract$1 } from 'ethers';
import './classPrivateMethodGet-55c9909f.esm.js';
import 'zustand/vanilla';
import 'zustand/middleware';
import 'eventemitter3';

// https://ethereum.org/en/developers/docs/standards/tokens/erc-20
const erc20ABI = ['event Approval(address indexed _owner, address indexed _spender, uint256 _value)', 'event Transfer(address indexed _from, address indexed _to, uint256 _value)', 'function allowance(address _owner, address _spender) public view returns (uint256 remaining)', 'function approve(address _spender, uint256 _value) public returns (bool success)', 'function balanceOf(address _owner) public view returns (uint256 balance)', 'function decimals() public view returns (uint8)', 'function name() public view returns (string)', 'function symbol() public view returns (string)', 'function totalSupply() public view returns (uint256)', 'function transfer(address _to, uint256 _value) public returns (bool success)', 'function transferFrom(address _from, address _to, uint256 _value) public returns (bool success)']; // https://ethereum.org/en/developers/docs/standards/tokens/erc-721

const erc721ABI = ['event Approval(address indexed _owner, address indexed _approved, uint256 indexed _tokenId)', 'event ApprovalForAll(address indexed _owner, address indexed _operator, bool _approved)', 'event Transfer(address indexed _from, address indexed _to, uint256 indexed _tokenId)', 'function approve(address _approved, uint256 _tokenId) external payable', 'function balanceOf(address _owner) external view returns (uint256)', 'function getApproved(uint256 _tokenId) external view returns (address)', 'function isApprovedForAll(address _owner, address _operator) external view returns (bool)', 'function name() view returns (string memory)', 'function ownerOf(uint256 _tokenId) external view returns (address)', 'function safeTransferFrom(address _from, address _to, uint256 _tokenId) external payable', 'function safeTransferFrom(address _from, address _to, uint256 _tokenId, bytes data) external payable', 'function setApprovalForAll(address _operator, bool _approved) external', 'function symbol() view returns (string memory)', 'function tokenByIndex(uint256 _index) view returns (uint256)', 'function tokenOfOwnerByIndex(address _owner, uint256 _index) view returns (uint256 tokenId)', 'function tokenURI(uint256 _tokenId) view returns (string memory)', 'function totalSupply() view returns (uint256)', 'function transferFrom(address _from, address _to, uint256 _tokenId) external payable'];

// https://github.com/ethers-io/ethers.js/blob/master/packages/units/src.ts/index.ts#L10-L18
const units = ['wei', 'kwei', 'mwei', 'gwei', 'szabo', 'finney', 'ether'];

async function connect(_ref) {
  let {
    connector
  } = _ref;
  const activeConnector = client.connector;
  if (connector.id === (activeConnector === null || activeConnector === void 0 ? void 0 : activeConnector.id)) throw new ConnectorAlreadyConnectedError();
  const data = await connector.connect();
  client.setLastUsedConnector(connector.id);
  client.setState(x => ({ ...x,
    connector,
    chains: connector === null || connector === void 0 ? void 0 : connector.chains,
    data
  }));
  client.storage.setItem('connected', true);
  return { ...data,
    connector
  };
}

async function disconnect() {
  const client = getClient();
  if (client.connector) await client.connector.disconnect();
  client.clearState();
  client.storage.removeItem('connected');
}

function getProvider() {
  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = getClient();
  if (chainId && typeof client.config.provider === 'function') return client.config.provider({
    chainId
  });
  return client.provider;
}

function getWebSocketProvider() {
  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const client = getClient();
  if (chainId && typeof client.config.webSocketProvider === 'function') return client.config.webSocketProvider({
    chainId
  });
  return client.webSocketProvider;
}

function watchProvider(args, callback) {
  const client = getClient();

  const handleChange = async () => callback(getProvider(args));

  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return unsubscribe;
}

function watchWebSocketProvider(args, callback) {
  const client = getClient();

  const handleChange = async () => callback(getWebSocketProvider(args));

  const unsubscribe = client.subscribe(_ref => {
    let {
      webSocketProvider
    } = _ref;
    return webSocketProvider;
  }, handleChange);
  return unsubscribe;
}

async function fetchBalance(_ref) {
  var _client$connector$cha, _client$connector, _chain$nativeCurrency, _chain$nativeCurrency2, _chain$nativeCurrency3, _chain$nativeCurrency4;

  let {
    addressOrName,
    chainId,
    formatUnits: unit = 'ether',
    token
  } = _ref;
  const client = getClient();
  const provider = getProvider({
    chainId
  });

  if (token) {
    const contract = new Contract(token, erc20ABI, provider);
    const [value, decimals, symbol] = await Promise.all([contract.balanceOf(addressOrName), contract.decimals(), contract.symbol()]);
    return {
      decimals,
      formatted: formatUnits(value, unit),
      symbol,
      unit,
      value
    };
  }

  const chains = [...((_client$connector$cha = (_client$connector = client.connector) === null || _client$connector === void 0 ? void 0 : _client$connector.chains) !== null && _client$connector$cha !== void 0 ? _client$connector$cha : []), ...allChains];
  const value = await provider.getBalance(addressOrName);
  const chain = chains.find(x => x.id === provider.network.chainId);
  return {
    decimals: (_chain$nativeCurrency = chain === null || chain === void 0 ? void 0 : (_chain$nativeCurrency2 = chain.nativeCurrency) === null || _chain$nativeCurrency2 === void 0 ? void 0 : _chain$nativeCurrency2.decimals) !== null && _chain$nativeCurrency !== void 0 ? _chain$nativeCurrency : 18,
    formatted: formatUnits(value, unit),
    symbol: (_chain$nativeCurrency3 = chain === null || chain === void 0 ? void 0 : (_chain$nativeCurrency4 = chain.nativeCurrency) === null || _chain$nativeCurrency4 === void 0 ? void 0 : _chain$nativeCurrency4.symbol) !== null && _chain$nativeCurrency3 !== void 0 ? _chain$nativeCurrency3 : 'ETH',
    unit,
    value
  };
}

async function fetchSigner() {
  var _client$connector, _client$connector$get;

  const client = getClient();
  const signer = (await ((_client$connector = client.connector) === null || _client$connector === void 0 ? void 0 : (_client$connector$get = _client$connector.getSigner) === null || _client$connector$get === void 0 ? void 0 : _client$connector$get.call(_client$connector))) || null;
  return signer;
}

function getAccount() {
  const {
    data,
    connector
  } = getClient();
  return {
    address: data === null || data === void 0 ? void 0 : data.account,
    connector
  };
}

function getNetwork() {
  var _client$data, _client$data$chain, _client$chains, _find, _client$data2;

  const client = getClient();
  const chainId = (_client$data = client.data) === null || _client$data === void 0 ? void 0 : (_client$data$chain = _client$data.chain) === null || _client$data$chain === void 0 ? void 0 : _client$data$chain.id;
  const activeChains = (_client$chains = client.chains) !== null && _client$chains !== void 0 ? _client$chains : [];
  const activeChain = (_find = [...activeChains, ...allChains].find(x => x.id === chainId)) !== null && _find !== void 0 ? _find : {
    id: chainId,
    name: "Chain ".concat(chainId),
    rpcUrls: {
      default: ''
    }
  };
  return {
    chain: chainId ? { ...activeChain,
      ...((_client$data2 = client.data) === null || _client$data2 === void 0 ? void 0 : _client$data2.chain),
      id: chainId
    } : undefined,
    chains: activeChains
  };
}

async function signMessage(args) {
  try {
    const signer = await fetchSigner();
    if (!signer) throw new ConnectorNotFoundError();
    return await signer.signMessage(args.message);
  } catch (error_) {
    let error = error_;
    if (error_.code === 4001) error = new UserRejectedRequestError();
    throw error;
  }
}

async function signTypedData(args) {
  try {
    const signer = await fetchSigner();
    if (!signer) throw new ConnectorNotFoundError(); // Method name may be changed in the future, see https://docs.ethers.io/v5/api/signer/#Signer-signTypedData

    return await signer._signTypedData(args.domain, args.types, args.value);
  } catch (error_) {
    let error = error_;
    if (error_.code === 4001) error = new UserRejectedRequestError();
    throw error;
  }
}

async function switchNetwork(_ref) {
  var _client$connector;

  let {
    chainId
  } = _ref;
  const client = getClient();
  if (!((_client$connector = client.connector) !== null && _client$connector !== void 0 && _client$connector.switchChain)) throw new SwitchChainNotSupportedError();
  const chain = await client.connector.switchChain(chainId);
  return chain;
}

function watchAccount(callback) {
  const client = getClient();

  const handleChange = () => callback(getAccount());

  const unsubscribe = client.subscribe(_ref => {
    let {
      data,
      connector
    } = _ref;
    return {
      account: data === null || data === void 0 ? void 0 : data.account,
      connector
    };
  }, handleChange, {
    equalityFn: (selected, previous) => selected.account === previous.account && selected.connector === previous.connector
  });
  return unsubscribe;
}

function watchNetwork(callback) {
  const client = getClient();

  const handleChange = () => callback(getNetwork());

  const unsubscribe = client.subscribe(_ref => {
    var _data$chain;

    let {
      data,
      chains
    } = _ref;
    return {
      chainId: data === null || data === void 0 ? void 0 : (_data$chain = data.chain) === null || _data$chain === void 0 ? void 0 : _data$chain.id,
      chains
    };
  }, handleChange, {
    equalityFn: (selected, previous) => selected.chainId === previous.chainId && selected.chains === previous.chains
  });
  return unsubscribe;
}

function watchSigner(callback) {
  const client = getClient();

  const handleChange = async () => callback(await fetchSigner());

  const unsubscribe = client.subscribe(_ref => {
    var _data$chain;

    let {
      data,
      connector
    } = _ref;
    return {
      account: data === null || data === void 0 ? void 0 : data.account,
      chainId: data === null || data === void 0 ? void 0 : (_data$chain = data.chain) === null || _data$chain === void 0 ? void 0 : _data$chain.id,
      connector
    };
  }, handleChange, {
    equalityFn: (selected, previous) => selected.account === previous.account && selected.chainId === previous.chainId && selected.connector === previous.connector
  });
  return unsubscribe;
}

function getContract(_ref) {
  let {
    addressOrName,
    contractInterface,
    signerOrProvider
  } = _ref;
  return new Contract$1(addressOrName, contractInterface, signerOrProvider);
}

async function readContract(contractConfig, functionName) {
  let {
    args,
    chainId,
    overrides
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const provider = getProvider({
    chainId
  });
  const contract = getContract({
    signerOrProvider: provider,
    ...contractConfig
  });
  const params = [...(Array.isArray(args) ? args : args ? [args] : []), ...(overrides ? [overrides] : [])];
  const contractFunction = contract[functionName];
  if (!contractFunction) console.warn("\"".concat(functionName, "\" does not in interface for contract \"").concat(contractConfig.addressOrName, "\""));
  const response = await (contractFunction === null || contractFunction === void 0 ? void 0 : contractFunction(...params));
  return response;
}

function watchContractEvent(
/** Contract configuration */
contractArgs,
/** Event name to listen to */
eventName, callback) {
  let {
    chainId,
    once
  } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  let contract;

  const watchEvent = async () => {
    if (contract) {
      var _contract;

      (_contract = contract) === null || _contract === void 0 ? void 0 : _contract.off(eventName, callback);
    }

    contract = getContract({
      signerOrProvider: getWebSocketProvider({
        chainId
      }) || getProvider({
        chainId
      }),
      ...contractArgs
    });
    if (once) contract.once(eventName, callback);else contract.on(eventName, callback);
  };

  watchEvent();
  const client = getClient();
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider,
      webSocketProvider
    } = _ref;
    return {
      provider,
      webSocketProvider
    };
  }, watchEvent, {
    equalityFn: (selected, previous) => selected.provider === previous.provider && selected.webSocketProvider === previous.webSocketProvider
  });
  return () => {
    var _contract2;

    (_contract2 = contract) === null || _contract2 === void 0 ? void 0 : _contract2.off(eventName, callback);
    unsubscribe();
  };
}

async function fetchBlockNumber() {
  let {
    chainId
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const provider = getProvider({
    chainId
  });
  const blockNumber = await provider.getBlockNumber();
  return blockNumber;
}

function watchBlockNumber(args, callback) {
  var _client$webSocketProv;

  let prevProvider;

  const createListener = provider => {
    if (prevProvider) {
      var _prevProvider;

      (_prevProvider = prevProvider) === null || _prevProvider === void 0 ? void 0 : _prevProvider.off('block', callback);
    }

    provider.on('block', callback);
    prevProvider = provider;
  };

  const client = getClient();
  const provider_ = (_client$webSocketProv = client.webSocketProvider) !== null && _client$webSocketProv !== void 0 ? _client$webSocketProv : client.provider;
  if (args.listen) createListener(provider_);
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider,
      webSocketProvider
    } = _ref;
    return {
      provider,
      webSocketProvider
    };
  }, async _ref2 => {
    let {
      provider,
      webSocketProvider
    } = _ref2;
    const provider_ = webSocketProvider !== null && webSocketProvider !== void 0 ? webSocketProvider : provider;

    if (args.listen && provider_) {
      createListener(provider_);
    }

    callback(await fetchBlockNumber());
  }, {
    equalityFn: (selected, previous) => selected.provider === previous.provider && selected.webSocketProvider === previous.webSocketProvider
  });
  return () => {
    unsubscribe();
    provider_ === null || provider_ === void 0 ? void 0 : provider_.off('block', callback);
  };
}

function watchReadContract(contractConfig, functionName) {
  let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let callback = arguments.length > 3 ? arguments[3] : undefined;
  const client = getClient();

  const handleChange = async () => callback(await readContract(contractConfig, functionName, config));

  const unwatch = config.listenToBlock ? watchBlockNumber({
    listen: true
  }, handleChange) : undefined;
  const unsubscribe = client.subscribe(_ref => {
    let {
      provider
    } = _ref;
    return provider;
  }, handleChange);
  return () => {
    unsubscribe();
    unwatch === null || unwatch === void 0 ? void 0 : unwatch();
  };
}

async function writeContract(contractConfig, functionName) {
  let {
    args,
    overrides
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const client = getClient();
  if (!client.connector) throw new ConnectorNotFoundError();
  const params = [...(Array.isArray(args) ? args : args ? [args] : []), ...(overrides ? [overrides] : [])];

  try {
    const signer = await client.connector.getSigner();
    const contract = getContract(contractConfig);
    const contractWithSigner = contract.connect(signer);
    const contractFunction = contractWithSigner[functionName];
    if (!contractFunction) console.warn("\"".concat(functionName, "\" does not exist in interface for contract \"").concat(contractConfig.addressOrName, "\""));
    const response = await contractFunction(...params);
    return response;
  } catch (error_) {
    let error = error_;
    if (error_.code === 4001) error = new UserRejectedRequestError();
    throw error;
  }
}

async function fetchEnsAddress(_ref) {
  let {
    chainId,
    name
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const address = await provider.resolveName(name);

  try {
    return address ? getAddress(address) : null;
  } catch (_error) {
    return null;
  }
}

async function fetchEnsAvatar(_ref) {
  let {
    addressOrName,
    chainId
  } = _ref;
  const provider = getProvider({
    chainId
  }); // TODO: Update with more advanced logic
  // https://github.com/ensdomains/ens-avatar

  const avatar = await provider.getAvatar(addressOrName);
  return avatar;
}

async function fetchEnsName(_ref) {
  let {
    address,
    chainId
  } = _ref;
  const provider = getProvider({
    chainId
  });
  return await provider.lookupAddress(address);
}

async function fetchEnsResolver(_ref) {
  let {
    chainId,
    name
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const resolver = await provider.getResolver(name);
  return resolver;
}

async function fetchFeeData() {
  let {
    chainId,
    formatUnits: units = 'wei'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const provider = getProvider({
    chainId
  });
  const feeData = await provider.getFeeData();
  const formatted = {
    gasPrice: formatUnits(feeData.gasPrice, units),
    maxFeePerGas: formatUnits(feeData.maxFeePerGas, units),
    maxPriorityFeePerGas: formatUnits(feeData.maxPriorityFeePerGas, units)
  };
  return { ...feeData,
    formatted
  };
}

async function fetchToken(_ref) {
  let {
    address,
    chainId,
    formatUnits: units = 'ether'
  } = _ref;
  const provider = getProvider({
    chainId
  });
  const contract = new Contract(address, erc20ABI, provider);
  const [symbol, decimals, totalSupply] = await Promise.all([contract.symbol(), contract.decimals(), contract.totalSupply()]);
  const token = {
    address,
    decimals,
    symbol,
    totalSupply: {
      formatted: formatUnits(totalSupply, units),
      value: totalSupply
    }
  };
  return token;
}

async function sendTransaction(args) {
  try {
    const signer = await fetchSigner();
    if (!signer) throw new ConnectorNotFoundError();
    const transaction = await signer.sendTransaction(args.request);
    return transaction;
  } catch (error_) {
    let error = error_;
    if (error_.code === 4001) error = new UserRejectedRequestError();
    throw error;
  }
}

async function waitForTransaction(_ref) {
  let {
    chainId,
    confirmations,
    hash,
    timeout,
    wait: wait_
  } = _ref;
  let promise;

  if (hash) {
    const provider = getProvider({
      chainId
    });
    promise = provider.waitForTransaction(hash, confirmations, timeout);
  } else if (wait_) promise = wait_(confirmations);else throw new Error('hash or wait is required');

  return await promise;
}

export { connect, disconnect, erc20ABI, erc721ABI, fetchBalance, fetchBlockNumber, fetchEnsAddress, fetchEnsAvatar, fetchEnsName, fetchEnsResolver, fetchFeeData, fetchSigner, fetchToken, getAccount, getContract, getNetwork, getProvider, getWebSocketProvider, readContract, sendTransaction, signMessage, signTypedData, switchNetwork, units, waitForTransaction, watchAccount, watchBlockNumber, watchContractEvent, watchNetwork, watchProvider, watchReadContract, watchSigner, watchWebSocketProvider, writeContract };
