import EventEmitter from 'eventemitter3';

function _checkPrivateRedeclaration(obj, privateCollection) {
  if (privateCollection.has(obj)) {
    throw new TypeError("Cannot initialize the same private elements twice on an object");
  }
}

function _classPrivateFieldInitSpec(obj, privateMap, value) {
  _checkPrivateRedeclaration(obj, privateMap);
  privateMap.set(obj, value);
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _classApplyDescriptorGet(receiver, descriptor) {
  if (descriptor.get) {
    return descriptor.get.call(receiver);
  }

  return descriptor.value;
}

function _classExtractFieldDescriptor(receiver, privateMap, action) {
  if (!privateMap.has(receiver)) {
    throw new TypeError("attempted to " + action + " private field on non-instance");
  }

  return privateMap.get(receiver);
}

function _classPrivateFieldGet(receiver, privateMap) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "get");
  return _classApplyDescriptorGet(receiver, descriptor);
}

function _classApplyDescriptorSet(receiver, descriptor, value) {
  if (descriptor.set) {
    descriptor.set.call(receiver, value);
  } else {
    if (!descriptor.writable) {
      throw new TypeError("attempted to set read only private field");
    }

    descriptor.value = value;
  }
}

function _classPrivateFieldSet(receiver, privateMap, value) {
  var descriptor = _classExtractFieldDescriptor(receiver, privateMap, "set");
  _classApplyDescriptorSet(receiver, descriptor, value);
  return value;
}

const etherscanBlockExplorers = {
  mainnet: {
    name: 'Etherscan',
    url: 'https://etherscan.io'
  },
  ropsten: {
    name: 'Etherscan',
    url: 'https://ropsten.etherscan.io'
  },
  rinkeby: {
    name: 'Etherscan',
    url: 'https://rinkeby.etherscan.io'
  },
  goerli: {
    name: 'Etherscan',
    url: 'https://goerli.etherscan.io'
  },
  kovan: {
    name: 'Etherscan',
    url: 'https://kovan.etherscan.io'
  },
  optimism: {
    name: 'Etherscan',
    url: 'https://optimistic.etherscan.io'
  },
  optimismKovan: {
    name: 'Etherscan',
    url: 'https://kovan-optimistic.etherscan.io'
  },
  polygon: {
    name: 'PolygonScan',
    url: 'https://polygonscan.com'
  },
  polygonMumbai: {
    name: 'PolygonScan',
    url: 'https://mumbai.polygonscan.com'
  },
  arbitrum: {
    name: 'Arbiscan',
    url: 'https://arbiscan.io'
  },
  arbitrumRinkeby: {
    name: 'Arbiscan',
    url: 'https://testnet.arbiscan.io'
  }
};

const defaultAlchemyId = '_gg7wSSi0KMBsdKnGVfHDueq6xMB9EkC';
const alchemyRpcUrls = {
  mainnet: 'https://eth-mainnet.alchemyapi.io/v2',
  ropsten: 'https://eth-ropsten.alchemyapi.io/v2',
  rinkeby: 'https://eth-rinkeby.alchemyapi.io/v2',
  goerli: 'https://eth-goerli.alchemyapi.io/v2',
  kovan: 'https://eth-kovan.alchemyapi.io/v2',
  optimism: 'https://opt-mainnet.g.alchemy.com/v2',
  optimismKovan: 'https://opt-kovan.g.alchemy.com/v2',
  polygon: 'https://polygon-mainnet.g.alchemy.com/v2',
  polygonMumbai: 'https://polygon-mumbai.g.alchemy.com/v2',
  arbitrum: 'https://arb-mainnet.g.alchemy.com/v2',
  arbitrumRinkeby: 'https://arb-rinkeby.g.alchemy.com/v2'
};
const infuraRpcUrls = {
  mainnet: 'https://mainnet.infura.io/v3',
  ropsten: 'https://ropsten.infura.io/v3',
  rinkeby: 'https://rinkeby.infura.io/v3',
  goerli: 'https://goerli.infura.io/v3',
  kovan: 'https://kovan.infura.io/v3',
  optimism: 'https://optimism-mainnet.infura.io/v3',
  optimismKovan: 'https://optimism-kovan.infura.io/v3',
  polygon: 'https://polygon-mainnet.infura.io/v3',
  polygonMumbai: 'https://polygon-mumbai.infura.io/v3',
  arbitrum: 'https://arbitrum-mainnet.infura.io/v3',
  arbitrumRinkeby: 'https://arbitrum-rinkeby.infura.io/v3'
};

const chainId = {
  mainnet: 1,
  ropsten: 3,
  rinkeby: 4,
  goerli: 5,
  kovan: 42,
  optimism: 10,
  optimismKovan: 69,
  polygon: 137,
  polygonMumbai: 80001,
  arbitrum: 42161,
  arbitrumRinkeby: 421611,
  localhost: 1337,
  hardhat: 31337
};
const chain = {
  mainnet: {
    id: chainId.mainnet,
    name: 'Ethereum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.mainnet,
      infura: infuraRpcUrls.mainnet,
      default: "".concat(alchemyRpcUrls.mainnet, "/").concat(defaultAlchemyId)
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.mainnet,
      default: etherscanBlockExplorers.mainnet
    }
  },
  ropsten: {
    id: chainId.ropsten,
    name: 'Ropsten',
    nativeCurrency: {
      name: 'Ropsten Ether',
      symbol: 'ropETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.ropsten,
      infura: infuraRpcUrls.ropsten,
      default: "".concat(alchemyRpcUrls.ropsten, "/").concat(defaultAlchemyId)
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.ropsten,
      default: etherscanBlockExplorers.ropsten
    },
    testnet: true
  },
  rinkeby: {
    id: chainId.rinkeby,
    name: 'Rinkeby',
    nativeCurrency: {
      name: 'Rinkeby Ether',
      symbol: 'rETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.rinkeby,
      infura: infuraRpcUrls.rinkeby,
      default: "".concat(alchemyRpcUrls.rinkeby, "/").concat(defaultAlchemyId)
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.rinkeby,
      default: etherscanBlockExplorers.rinkeby
    },
    testnet: true
  },
  goerli: {
    id: chainId.goerli,
    name: 'Goerli',
    nativeCurrency: {
      name: 'Goerli Ether',
      symbol: 'gETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.goerli,
      infura: infuraRpcUrls.goerli,
      default: "".concat(alchemyRpcUrls.goerli, "/").concat(defaultAlchemyId)
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.goerli,
      default: etherscanBlockExplorers.goerli
    },
    testnet: true
  },
  kovan: {
    id: chainId.kovan,
    name: 'Kovan',
    nativeCurrency: {
      name: 'Kovan Ether',
      symbol: 'kETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.kovan,
      infura: infuraRpcUrls.kovan,
      default: "".concat(alchemyRpcUrls.kovan, "/").concat(defaultAlchemyId)
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.kovan,
      default: etherscanBlockExplorers.kovan
    },
    testnet: true
  },
  optimism: {
    id: chainId.optimism,
    name: 'Optimism',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.optimism,
      infura: infuraRpcUrls.optimism,
      default: 'https://mainnet.optimism.io'
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.optimism,
      default: etherscanBlockExplorers.optimism
    }
  },
  optimismKovan: {
    id: chainId.optimismKovan,
    name: 'Optimism Kovan',
    nativeCurrency: {
      name: 'Kovan Ether',
      symbol: 'KOR',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.optimismKovan,
      infura: infuraRpcUrls.optimismKovan,
      default: 'https://kovan.optimism.io'
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.optimismKovan,
      default: etherscanBlockExplorers.optimismKovan
    },
    testnet: true
  },
  polygon: {
    id: chainId.polygon,
    name: 'Polygon',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.polygon,
      infura: infuraRpcUrls.polygon,
      default: 'https://polygon-rpc.com'
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.polygon,
      default: etherscanBlockExplorers.polygon
    }
  },
  polygonMumbai: {
    id: chainId.polygonMumbai,
    name: 'Polygon Mumbai',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.polygonMumbai,
      infura: infuraRpcUrls.polygonMumbai,
      default: 'https://matic-mumbai.chainstacklabs.com'
    },
    blockExplorers: {
      etherscan: etherscanBlockExplorers.polygonMumbai,
      default: etherscanBlockExplorers.polygonMumbai
    },
    testnet: true
  },
  arbitrum: {
    id: chainId.arbitrum,
    name: 'Arbitrum',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'AETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.arbitrum,
      infura: infuraRpcUrls.arbitrum,
      default: 'https://arb1.arbitrum.io/rpc'
    },
    blockExplorers: {
      arbitrum: {
        name: 'Arbitrum Explorer',
        url: 'https://explorer.arbitrum.io'
      },
      etherscan: etherscanBlockExplorers.arbitrum,
      default: etherscanBlockExplorers.arbitrum
    }
  },
  arbitrumRinkeby: {
    id: chainId.arbitrumRinkeby,
    name: 'Arbitrum Rinkeby',
    nativeCurrency: {
      name: 'Arbitrum Rinkeby Ether',
      symbol: 'ARETH',
      decimals: 18
    },
    rpcUrls: {
      alchemy: alchemyRpcUrls.arbitrumRinkeby,
      infura: infuraRpcUrls.arbitrumRinkeby,
      default: 'https://rinkeby.arbitrum.io/rpc'
    },
    blockExplorers: {
      arbitrum: {
        name: 'Arbitrum Explorer',
        url: 'https://rinkeby-explorer.arbitrum.io'
      },
      etherscan: etherscanBlockExplorers.arbitrumRinkeby,
      default: etherscanBlockExplorers.arbitrumRinkeby
    },
    testnet: true
  },
  localhost: {
    id: chainId.localhost,
    name: 'Localhost',
    rpcUrls: {
      default: 'http://127.0.0.1:8545'
    }
  },
  hardhat: {
    id: chainId.hardhat,
    name: 'Hardhat',
    rpcUrls: {
      default: 'http://127.0.0.1:8545'
    }
  }
};
const allChains = Object.values(chain);
const defaultChains = [chain.mainnet, chain.ropsten, chain.rinkeby, chain.goerli, chain.kovan];
const defaultL2Chains = [chain.arbitrum, chain.arbitrumRinkeby, chain.optimism, chain.optimismKovan];

function normalizeChainId(chainId) {
  if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().substring(0, 2) === '0x' ? 16 : 10);
  return chainId;
}

class AddChainError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'AddChainError');

    _defineProperty(this, "message", 'Error adding chain');
  }

}
class ChainNotConfiguredError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ChainNotConfigured');

    _defineProperty(this, "message", 'Chain not configured');
  }

}
class ConnectorAlreadyConnectedError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ConnectorAlreadyConnectedError');

    _defineProperty(this, "message", 'Connector already connected');
  }

}
class ConnectorNotFoundError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'ConnectorNotFoundError');

    _defineProperty(this, "message", 'Connector not found');
  }

}
class SwitchChainError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'SwitchChainError');

    _defineProperty(this, "message", 'Error switching chain');
  }

}
class SwitchChainNotSupportedError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'SwitchChainNotSupportedError');

    _defineProperty(this, "message", 'Switch chain not supported by connector');
  }

}
class UserRejectedRequestError extends Error {
  constructor() {
    super(...arguments);

    _defineProperty(this, "name", 'UserRejectedRequestError');

    _defineProperty(this, "message", 'User rejected request');
  }

}

class Connector extends EventEmitter {
  /** Unique connector id */

  /** Connector name */

  /** Chains connector supports */

  /** Options to use with connector */

  /** Whether connector is usable */
  constructor(_ref) {
    let {
      chains = defaultChains,
      options
    } = _ref;
    super();

    _defineProperty(this, "id", void 0);

    _defineProperty(this, "name", void 0);

    _defineProperty(this, "chains", void 0);

    _defineProperty(this, "options", void 0);

    _defineProperty(this, "ready", void 0);

    this.chains = chains;
    this.options = options;
  }

  getBlockExplorerUrls(chain) {
    var _chain$blockExplorers;

    const blockExplorer = (_chain$blockExplorers = chain.blockExplorers) === null || _chain$blockExplorers === void 0 ? void 0 : _chain$blockExplorers.default;
    if (Array.isArray(blockExplorer)) return blockExplorer.map(x => x.url);
    if (blockExplorer !== null && blockExplorer !== void 0 && blockExplorer.url) return [blockExplorer.url];
    return [];
  }

  isChainUnsupported(chainId) {
    return !this.chains.some(x => x.id === chainId);
  }

}

export { AddChainError as A, ConnectorAlreadyConnectedError as C, SwitchChainNotSupportedError as S, UserRejectedRequestError as U, _defineProperty as _, allChains as a, ConnectorNotFoundError as b, Connector as c, alchemyRpcUrls as d, chain as e, chainId as f, defaultChains as g, defaultL2Chains as h, etherscanBlockExplorers as i, infuraRpcUrls as j, ChainNotConfiguredError as k, SwitchChainError as l, _classPrivateFieldInitSpec as m, normalizeChainId as n, _classPrivateFieldSet as o, _classPrivateFieldGet as p, _checkPrivateRedeclaration as q };
